import React from "react";
import PropTypes from 'prop-types';
import Image from "./image";
import useIntersectionObserver from "../use-intersection-observer";
import "./image-container.css";

const ImageContainer = props => {
  const ref = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  useIntersectionObserver({
    target: ref,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        if (!isVisible) {
          if (props.onIsVisible) {
            props.onIsVisible();
          }
          setIsVisible(true);
        }
        observerElement.unobserve(ref.current);
      }
    }
  });

  const aspectRatio = (props.height / props.width) * 100;

  return (
    <div
      ref={ref}
      className={`image-container ${props.className}`}
      style={{ paddingBottom: `${aspectRatio}%`, height: props.height, width: props.width }}
      className={props.className}
      onClick={props.onClick}
    >

      {isVisible && (
        <Image src={props.src} thumb={props.thumb} alt={props.alt} style={props.imgStyle} />
      ) }
    </div>
  );
};


ImageContainer.propTypes = {
  className: PropTypes.string,
  imgStyle: PropTypes.shape({}),
  height: PropTypes.string,
  width: PropTypes.string
}

ImageContainer.defaultProps = {
  className: '',
  imgStyle: {},
  height: '100%',
  width: '100%'
}


export default ImageContainer;
