import React from "react";
import PropTypes from "prop-types";
import { useStyletron } from 'baseui';
import "./image.css";

const Image = props => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [css, theme] = useStyletron();

  const onImgLoad = () => {
    setIsLoaded(true);
  }

  let thumbStyle = { visibility: isLoaded ? "hidden" : "visible" }
  let imgStyle = { opacity: isLoaded ? 1 : 0 }
  return (
    <React.Fragment>
      <img
        className={`image thumb ${css({ ...props.style})}`}
        // alt={props.alt}
        // src={props.thumb}
        style={thumbStyle}
      />
      <img
        onLoad={onImgLoad}
        className={`image full ${css({ ...props.style})}`}
        style={imgStyle}
        alt={props.alt}
        src={props.src}
      />
    </React.Fragment>
  );
};

Image.propTypes = {
  style: PropTypes.shape({})
}

export default Image;
