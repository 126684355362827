import * as React from 'react';
import {
    Modal,
    ModalBody,
    SIZE,
    ROLE,
} from 'baseui/modal';
import { ArrowDown, ChevronLeft, ChevronRight, Delete, Upload, Show } from "baseui/icon";
import { Spinner } from 'baseui/spinner';

const loadImage = (src, onload, onerror) => {
    const img = new Image();
    img.onerror = onerror
    img.onload = onload
    img.src = src
}

export default ({ src, isOpen, onClose, gotoRight, gotoLeft, theme }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    React.useEffect(() => {
        loadImage(src, (data) => {
            setIsLoading(false);
        }, () => {
            setIsError(true);
            setIsLoading(false);
        })
    });

    return (
        <Modal
            onClose={onClose}
            closeable={true}
            isOpen={isOpen}
            animate
            autoFocus
            size={SIZE.default}
            role={ROLE.dialog}
            overrides={{
                Dialog: {
                    style: {
                        width: '100vw',
                        height: '95vh',
                        display: 'flex',
                        flexDirection: 'column',
                        background: 'transparent',
                        backgroundColor: 'rgb(255, 255, 255, 0)'
                    },
                },
                Backdrop: {
                    style: ({ $theme }) => ({
                        backdropFilter: $theme.modalBackDropFilter,
                    }),
                },
                Close: {
                    style: ({ $theme }) => ({
                        color: 'white',
                        position: 'absolute',
                        right: '10px',
                        top: '10px'
                    }),
                },
                Root: {
                    style: {
                        zIndex: 3,
                        background: 'transparent',
                    }
                },

            }}
        >
            <ModalBody>
                <>
                    {isLoading ? <div
                        style={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spinner size={96} />
                    </div> : (
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                            {gotoLeft && (
                                <div
                                    onClick={gotoLeft}
                                    style={{
                                        height: '64px',
                                        width: '64px', left: '16px', cursor: 'pointer'
                                    }}>
                                    <ChevronLeft size={64} color={theme.colors.primaryA} />
                                </div>
                            )}
                            <div style={{minWidth: '80vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <img src={src} style={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain'
                                }}/>
                            </div>
                            {gotoRight && (
                                <div
                                    onClick={gotoRight}
                                    style={{
                                        height: '64px',
                                        width: '64px', right: '16px', cursor: 'pointer'
                                    }}>
                                    <ChevronRight size={64} color={theme.colors.primaryA} />
                                </div>
                            )}
                        </div>
                    )}
                </>
            </ModalBody>
        </Modal>
    )
}