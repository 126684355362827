import React, { Component, Node } from 'react';
import ReactDOM from 'react-dom';

let portal;

if (typeof window !== `undefined`) {
  portal = document.createElement('div');
  portal.classList.add('my-super-cool-portal');
  if (!document.body) {
    throw new Error('body not ready for portal creation!');
  }
  document.body.appendChild(portal);
};


class PortalAwareItem extends Component {
    render() {
        const {snapshot, child} = this.props;
        const usePortal = snapshot.isDragging;
        if (!usePortal) {
            return child;
        }
        return ReactDOM.createPortal(child, portal);
    }
}

export default PortalAwareItem;
